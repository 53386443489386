import React from 'react';
import PropTypes from 'prop-types';
import {
  graphql,
} from 'gatsby';
import Link from 'gatsby-link';
import rehypeReact from 'rehype-react';
import {
  Container,
  Header,
  Breadcrumb,
} from 'semantic-ui-react';

// eslint-disable-next-line new-cap
const renderAst = new rehypeReact({
  createElement: React.createElement,
}).Compiler;

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data; // data.markdownRemark holds your post data
  const { frontmatter, htmlAst } = markdownRemark;
  /* eslint-disable-next-line react/no-danger */
  return (
    <Container className="blog-post-container">
      <Breadcrumb size="massive">
        <Breadcrumb.Section href="/">Home</Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section href="/blog">Blog</Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section active>{frontmatter.title}</Breadcrumb.Section>
      </Breadcrumb>
      <Header as="h3">
        {frontmatter.date}
        <Header.Subheader>
          <Link key="tags_list" to="/blog/tags">tags: </Link>
          { frontmatter.tags && frontmatter.tags.map((item, key) => (
            <span key={`${item}-holder`}>
              <Link key={`${item}-link`} to={`/blog/tags/${item}`}>{item}</Link>
              {' '}
            </span>
          ))}
        </Header.Subheader>
      </Header>
      {renderAst(htmlAst)}
    </Container>
  );
}

Template.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.any.isRequired,
};

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      htmlAst
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        tags
      }
    }
  }
`;
